<template>
    <div class="col-12">
        <Swiper
            :modules="[SwiperNavigation, SwiperPagination, SwiperAutoplay]"
            :slides-per-view="1"
            :loop="true"
            :pagination="{ el: '.swiper-custom-pagination', clickable: true }"
            :scrollbar="true"
            class="swiper-size"
            :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
            }"
        >
            <SwiperSlide v-for="(slide, index) in slides" :key="index" class="text-center">
                <a :href="slide.url">
                    <img class="w-100" :src="pattern.test(slide.image) ? slide.image : runtimeConfig.public.STATIC_BASE_URL + slide.image" alt="" />
                </a>
            </SwiperSlide>
        </Swiper>
        <div class="swiper-custom-pagination" />
    </div>
</template>

<script setup lang="ts">
defineProps<{ slides: { image: string; url: string | null }[] }>();
const runtimeConfig = useRuntimeConfig();
const pattern = /^https?:\/\//i;
</script>

<style lang="scss">
.swiper-size {
    max-width: 46.25rem;
    width: 100%;
    margin-left: unset;
    margin-right: unset;
}

.swiper-custom-pagination {
    margin-top: 0.31rem;
    z-index: 1;
    display: flex;
    position: inherit;
    flex-direction: row;
    justify-content: center;
    height: auto;
    gap: 0.5rem;

    .swiper-pagination-bullet {
        width: 0.5rem;
        height: 0.5rem;
        margin: unset !important;
        background: #2b2b2b;
        opacity: unset;
    }

    .swiper-pagination-bullet-active {
        background: #e4003c;
    }
}

@media (max-width: 1200px) {
    .swiper-size {
        max-width: unset;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
